import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { Row, Col, Container } from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BackgroundImage from "gatsby-background-image"

const HeaderSection = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "home-feature-fish.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <BackgroundImage
          Tag="section"
          className="page-header"
          fluid={imageData}
          backgroundColor="#f2f3f3"
        >
          <Row>
            <Col xs={{ span: 6, offset: 3 }} md={{ span: 4, offset: 4 }} className="feature-bg">
              <h2>
                Events
              </h2>
            </Col>
          </Row>
        </BackgroundImage>
      )
    }}
  />
)

const EventsPage = () => (
  <Layout pageInfo={{ pageName: "events" }}>

    <SEO title="Events" keywords={[
      `flame and hog`,
      `hog roast`,
      `lamb roast`,
      `bbq catering`
    ]} />

    <div className="grey-bg">

      <HeaderSection/>

      <Container>
        <Row className="global-padding">
          <Col md={{ span: 8, offset: 2 }}>
            <p>
              At Flame &amp; Hog we can cater for any event whether it be a private party, Wedding (we can offer a wedding breakfast or just to feed your evening guests), Corporate event, School function, birthday party, the list is endless what ever the occasion.
            </p>
            <p>
              The Flame &amp; Hog company are experts in making your special event perfect, what ever the occasion. Its very important that we get to know our clients personally as everyone's event is unique. With this in mine we can make your experience truly memorable.
            </p>
            <p>
              Hog roasts &amp; Barbecues are a very cost effective way of feeding your guests. This may allow you to afford additional items for your function.
            </p>
          </Col>
        </Row>
      </Container>
    </div>

  </Layout>
)

export default EventsPage
